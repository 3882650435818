.root {
  color: var(--color-grey-1000);
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.container {
  margin: 120px 100px 0 100px;
  max-width: 1136px;
  width: calc(100vw - 200px);
}

.header {
  font-size: 64px;
  line-height: 1.25;
  font-weight: 600;
  max-width: 1136px;
  letter-spacing: -0.025em;
}

.subHeader {
  font-size: 24px;
  line-height: 1.25;
  font-weight: normal;
  margin-top: 32px;
  max-width: 813px;
  letter-spacing: -0.025em;
  color: var(--color-grey-600);
}

.arrowLink {
  font-size: 24px;
  margin-top: 24px;
  display: block;
}

.cta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 200px;
  position: relative;
}

.cta > * {
  flex: 0 55%;
}

.ctaContent {
  margin-right: 25px;
}

.ctaHeader {
  font-size: 40px;
  line-height: 1.25;
  font-weight: 600;
  letter-spacing: -0.025em;
}

.ctaLogo {
  height: 25px;
  display: block;
  margin: 25px 0;
}

.ctaImage {
  position: absolute;
  width: 55%;
  right: -200px;
  max-width: 800px;
}

.narrowImage {
  display: none;
}

.categories {
  list-style: none;
  display: flex;
  justify-content: space-around;
  margin: 110px 30px 90px 30px;
}

.categoryWrapper {
  flex: 1 1 auto;
  position: relative;
}

.category {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--color-grey-1000);
  text-decoration: none;
  padding: 20px 0;
  transition: background-color 300ms ease;
  border-radius: 16px;
  position: relative;
  letter-spacing: -0.025em;
}

.category:hover {
  background-color: var(--color-grey-60);
}

.category:active {
  background-color: var(--color-grey-80);
}

.subBannerChild:not(:last-of-type):after {
  content: '';
  height: 40px;
  width: 1px;
  background-color: var(--color-grey-80);
  position: absolute;
  right: 0;
}

.subBannerChild:not(:last-of-type):after {
  height: 185px;
}

.categoryImageContainer {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.categoryImage {
  display: inline-flex;
  align-self: center;
  max-height: 32px;
}

.categoryName {
  opacity: 0.7;
  font-size: 16px;
  line-height: 1.81;
  margin-top: 15px;
  text-align: center;
  text-decoration: none;
  color: inherit;
}

.subBanner {
  width: 100%;
  display: flex;
  justify-content: center;
}

.subBannerContainer {
  max-width: 1237px;
  width: 100vw;
  padding: 80px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
}

.subBannerChild {
  padding: 0 100px;
  display: flex;
  align-content: center;
  flex-direction: column;
  position: relative;
}

.subBannerLogo {
  height: 25px;
  margin-bottom: 26px;
}

.subBannerHeader {
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: -0.025em;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .categoryWrapper:not(:last-of-type):after {
    content: '';
    height: 40px;
    width: 1px;
    background-color: var(--color-grey-80);
    position: absolute;
    right: 0;
    top: 30px;
  }
}

@media (max-width: 1024px) {
  .container {
    max-width: calc(100vw - 80px);
    width: calc(100vw - 80px);
    margin-left: 40px;
    margin-right: 40px;
  }

  .header {
    font-size: 50px;
    line-height: 1.46;
  }

  .subHeader {
    font-size: 28px;
  }

  .categories {
    flex-wrap: wrap;
    margin: 60px 30px;
  }

  .categoryWrapper {
    flex-basis: 33.33%;
  }

  .category {
    padding: 30px 0;
    margin-left: 0;
    margin-right: 0;
  }

  .ctaHeader {
    font-size: 26px;
  }

  .ctaContent {
    margin: 0;
  }

  .subBannerHeader {
    font-size: 26px;
  }

  .arrowLink {
    font-size: 20px;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .categoryWrapper:not(:nth-of-type(3n)) {
    border-right: 1px solid var(--color-grey-80);
  }

  .categoryWrapper:not(:nth-of-type(-n + 3)) {
    border-top: 1px solid var(--color-grey-80);
  }
}

@media (max-width: 600px) {
  .container {
    max-width: calc(100vw - 40px);
    width: calc(100vw - 40px);
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 32px;
  }

  .header {
    font-size: 32px;
    line-height: 1.25;
  }

  .subHeader {
    font-size: 18px;
  }

  .cta {
    flex-direction: column-reverse;
    margin-bottom: 60px;
  }

  .ctaContent {
    margin: 0;
  }

  .ctaImage {
    max-width: 100vw;
  }

  .wideImage {
    display: none;
  }

  .narrowImage {
    position: relative;
    right: -20px;
    width: 90vw;
    display: block;
  }

  .categories {
    margin: 60px 0;
  }

  .categoryWrapper {
    flex-basis: 50%;
  }

  .categoryWrapper:not(:nth-of-type(2n)) {
    border-right: 1px solid var(--color-grey-80);
  }

  .categoryWrapper:not(:nth-of-type(-n + 2)) {
    border-top: 1px solid var(--color-grey-80);
  }

  .subBannerContainer {
    flex-direction: column;
  }

  .subBannerChild {
    padding: 20px;
  }

  .subBannerChild:first-of-type {
    margin-bottom: 50px;
  }

  .arrowLink {
    font-size: 16px;
  }
}
